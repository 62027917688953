import React from "react";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const ToggleButtons = ({ value, onChange, buttons }) => {
  const onButtonClick = (e, newValue) => {
    if (newValue !== null) {
      return onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      onChange={onButtonClick}
      exclusive
      size="small"
    >
      {buttons.map((button, idx) => (
        <ToggleButton
          key={`toggle-button-${idx}`}
          value={button.value}
          id={button.id}
        >
          {button.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default ToggleButtons;
