import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Avatar,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  IconButton,
  Popover,
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CancelIcon from "@material-ui/icons/Cancel";
import EcoIcon from "@material-ui/icons/Eco";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

import { DefaultPaper, Select } from "@aclymatedev/atoms";
import { tonsToLbs } from "@aclymatedev/converters";
import { formatDecimal, ucFirstLetters } from "@aclymatedev/formatters";

import Layout from "./Layout";
import PurchaseOffsetsForCompany, {
  LastOffsetPurchasedText,
  OffsetPreferencesText,
} from "./PurchaseOffsetsForCompany";
import SortableTable, { makeColumnObj } from "./modules/SortableTable";

import { apiFetch } from "../helpers/apiFetch";
import { useAuth } from "../helpers/firebase";
import { useCompanyDetails } from "../helpers/companyDetails";
import ToggleButtons from "./atoms/ToggleButtons";
import LoadingButton from "./atoms/LoadingButton";
import TextField from "./atoms/TextField";

const OffsetSubAccountRow = ({ company, setPurchaseOffsetsForCompany }) => {
  const { name, id, offsetPreferences = [] } = company;

  const [companyDetails] = useCompanyDetails(id);
  const {
    currentCarbonBalanceTons = 0,
    lastMonthsEmissionsTons = 0,
    averageMonthlyRecurringEmissionsTons = 0,
    offsets = [],
  } = companyDetails;
  const mostRecentOffset = offsets.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  )[0];

  const emissionsValues = [
    currentCarbonBalanceTons,
    lastMonthsEmissionsTons,
    averageMonthlyRecurringEmissionsTons,
  ];

  return (
    <TableRow>
      <TableCell>
        <Typography>{name}</Typography>
        <Typography variant="caption">{id}</Typography>
      </TableCell>
      {emissionsValues.map((value) => (
        <TableCell>
          <Typography variant="body2" align="center">
            {`${formatDecimal(tonsToLbs(value))} lbs`}
          </Typography>
        </TableCell>
      ))}
      <TableCell>
        <OffsetPreferencesText offsetPreferences={offsetPreferences} />
      </TableCell>
      <TableCell>
        <LastOffsetPurchasedText mostRecentOffset={mostRecentOffset} />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => setPurchaseOffsetsForCompany(company)}>
          <Tooltip title="Purchase offsets for company">
            <AddShoppingCartIcon />
          </Tooltip>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const AccountRow = ({
  company,
  setDialogOpen,
  setOffsetSubscriptionTag,
  setPurchaseOffsetsForCompany,
  setAddCompanyCoupon,
  tableView,
}) => {
  const { name, users, id, slug, isCertified } = company;

  const [user] = useAuth();
  const { email } = user;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  const onArchiveCompany = async () =>
    await apiFetch({
      user,
      path: "/aclymate-db-post/update-company-data",
      data: {
        companyId: id,
        updateObj: { archived: true },
      },
      method: "POST",
    }).then(() => setIsDeleted(true));

  return (
    <TableRow>
      {isDeleted ? (
        <TableCell colspan={5}>
          <Typography variant="h4">This company has been archived</Typography>
        </TableCell>
      ) : (
        <>
          <TableCell>
            <Typography>{name}</Typography>
            <Typography variant="caption">{id}</Typography>
          </TableCell>
          <TableCell>
            {!!users && Array.isArray(users) && (
              <AvatarGroup max={4}>
                {users
                  .filter(({ uid }) => uid)
                  .map(({ email, name }, idx) => (
                    <Tooltip
                      key={`user-cell-${idx}`}
                      title={`${name}- ${email}`}
                    >
                      <Avatar color="primary">
                        {name
                          .split(" ")
                          .map((str) => str.charAt(0))
                          .join("")
                          .toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  ))}
              </AvatarGroup>
            )}
          </TableCell>
          <TableCell>
            {isCertified ? (
              <Button
                variant="contained"
                href={`https://aclymate.com/climate-leaders/${slug}`}
                target="_blank"
              >
                View Page
              </Button>
            ) : (
              "Not a Climate Leader"
            )}
          </TableCell>
          <TableCell>
            <Button variant="contained" onClick={() => setDialogOpen(id)}>
              View Details
            </Button>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => setAddCompanyCoupon(company)}>
              <Tooltip title="Add a coupon to a company's next purchase">
                <LocalOfferIcon />
              </Tooltip>
            </IconButton>
            {["mike@aclymate.com", "william@aclymate.com"].includes(email) && (
              <>
                <Tooltip title="Archive Company">
                  <IconButton
                    onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DefaultPaper>
                    <Button
                      variant="contained"
                      onClick={onArchiveCompany}
                    >{`Archive ${name}?`}</Button>
                    <IconButton onClick={() => setAnchorEl(null)}>
                      <CancelIcon />
                    </IconButton>
                  </DefaultPaper>
                </Popover>
              </>
            )}
            {tableView === "normal" ? (
              <IconButton onClick={() => setOffsetSubscriptionTag(company)}>
                <Tooltip title="Tag company for offset subscription">
                  <EcoIcon />
                </Tooltip>
              </IconButton>
            ) : (
              <IconButton onClick={() => setPurchaseOffsetsForCompany(company)}>
                <Tooltip title="Purchase offsets for company">
                  <AddShoppingCartIcon />
                </Tooltip>
              </IconButton>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const OffsetSubscriptionTag = ({
  setOffsetSubscriptionTag,
  id,
  name,
  billing = {},
  slug,
}) => {
  const { subscriptions = [] } = billing;
  const isCompanyAlreadyTagged = !!subscriptions.includes("offsetSubscription");

  const [tagCompanyLoading, setTagCompanyLoading] = useState(false);
  const [isTagCompanySuccessful, setIsTagCompanySuccessful] = useState(
    isCompanyAlreadyTagged
  );
  const [user] = useAuth();

  const tagCompanyForOffsetSubscription = async () => {
    setTagCompanyLoading(true);
    const billingUpdateObj = {
      ...billing,
      subscriptions: [...subscriptions, "offsetSubscription"],
    };
    const newCompanySlug = name.split(" ").join("-").toLowerCase();
    const slugUpdateObj = !slug
      ? {
          slug: newCompanySlug,
        }
      : {};

    await apiFetch({
      user,
      path: "/aclymate-db-post/update-company-data",
      data: {
        companyId: id,
        updateObj: {
          billing: billingUpdateObj,
          ...slugUpdateObj,
        },
        environment: process.env.REACT_APP_ENVIRONMENT,
      },
      method: "POST",
    });

    return setIsTagCompanySuccessful(true);
  };

  return (
    <Dialog open onClose={() => setOffsetSubscriptionTag(null)}>
      <DialogTitle>{`Tag ${name} for an offset subscription`}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Typography variant="body1">
              Once you tag this company, you will be able to go to the "Offset
              Subs" table and manually purchase offsets for this company. Would
              you like to do this?
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {!isTagCompanySuccessful ? (
              <>
                <Grid item>
                  <LoadingButton
                    label="Yes"
                    color="primary"
                    variant="contained"
                    onClick={() => tagCompanyForOffsetSubscription()}
                    isLoading={tagCompanyLoading}
                  />
                </Grid>
                <Grid>
                  <LoadingButton
                    label="No"
                    color="secondary"
                    variant="contained"
                    onClick={() => setOffsetSubscriptionTag(null)}
                    isLoading={tagCompanyLoading}
                  />
                </Grid>
              </>
            ) : (
              <Grid item>
                <Typography variant="body2" style={{ color: "green" }}>
                  {isCompanyAlreadyTagged
                    ? "Company has already been tagged for an offset subscription."
                    : "Company was successfully tagged! You may now close this window."}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const AddCompanyCoupon = ({ setAddCompanyCoupon, name, id }) => {
  const [user, userLoading] = useAuth();

  const [couponId, setCouponId] = useState(null);
  const [couponOptions, setCouponOptions] = useState([]);
  const [couponOptionsLoading, setCouponOptionsLoading] = useState(true);
  const [usedCoupons, setUsedCoupons] = useState([]);
  const [usedCouponsLoading, setUsedCouponsLoading] = useState(true);

  const formatCouponOptions = (coupons) => {
    const { data } = coupons;

    const findPurchaseType = (name) => {
      const lowerCaseName = name.toLowerCase();
      if (lowerCaseName.includes("concierge")) {
        return "concierge";
      }

      if (
        lowerCaseName.includes("subscription") ||
        lowerCaseName.includes("saas") ||
        lowerCaseName.includes("analytics") ||
        lowerCaseName.includes("integrations") ||
        lowerCaseName.includes("business")
      ) {
        return "integrations";
      }

      return "offsets";
    };

    const formattedCoupons = data.map((coupon) => {
      const { id, name } = coupon;

      return {
        label: name,
        value: id,
        purchaseType: findPurchaseType(name),
        coupon,
      };
    });
    return setCouponOptions(formattedCoupons);
  };

  useEffect(() => {
    if (user && !userLoading && couponOptionsLoading) {
      apiFetch({
        user,
        path: `/admin-aclymate-get/fetch-coupons`,
        method: "GET",
        callback: (res) => formatCouponOptions(res),
      }).then(() => setCouponOptionsLoading(false));
    }
  }, [user, userLoading, couponOptionsLoading]);

  useEffect(() => {
    if (user && !userLoading && usedCouponsLoading) {
      apiFetch({
        user,
        path: `/companies/fetch-company-collection/${id}/${process.env.REACT_APP_ENVIRONMENT}/coupons`,
        method: "GET",
        callback: (res) => setUsedCoupons(res),
      }).then(() => setUsedCouponsLoading(false));
    }
  }, [user, userLoading, id, usedCouponsLoading]);

  const uploadCoupon = async () => {
    const selectedCoupon = couponOptions.find(
      ({ value }) => value === couponId
    );
    const { label, purchaseType, coupon } = selectedCoupon;

    return await apiFetch({
      user,
      path: "/aclymate-db-post/new-company-collection-doc",
      data: {
        companyId: id,
        collection: "coupons",
        docData: {
          couponId,
          couponName: label,
          purchaseType,
          coupon,
          used: false,
        },
      },
      method: "POST",
    }).then(() => setAddCompanyCoupon(null));
  };

  return (
    <Dialog open onClose={() => setAddCompanyCoupon(null)}>
      <DialogTitle>{`Add a coupon for ${name}`}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" spacing={2} direction="column">
          <Grid item>
            <Typography variant="body2" align="center">
              Select the type of coupon to add to the company. Before the
              company's next purchase, they will be notified that a coupon has
              been added to discount their purchase.
            </Typography>
          </Grid>
          <Grid item>
            <Select
              value={couponId}
              editValue={setCouponId}
              options={couponOptions}
              size="small"
              id="coupon-dropdown-select"
            />
          </Grid>
          <Grid item container justifyContent="center">
            <Button
              variant="contained"
              color="secondary"
              id="add-coupon-button"
              onClick={() => uploadCoupon()}
              disabled={!couponId}
            >
              Add Coupon
            </Button>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Typography variant="h5">
                Coupons added to this account:
              </Typography>
            </Grid>
            {!usedCoupons.length ? (
              <Grid item container justifyContent="center">
                <Typography variant="h6">None</Typography>
              </Grid>
            ) : (
              usedCoupons.map(({ couponName, used }) => (
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body1">{couponName}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{`Used: ${
                      used ? `Yes` : `No`
                    }`}</Typography>
                  </Grid>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const AccountsTable = ({ companies, setDialogOpen, tableView }) => {
  const [offsetSubscriptionTag, setOffsetSubscriptionTag] = useState(null);
  const [purchaseOffsetsForCompany, setPurchaseOffsetsForCompany] =
    useState(null);
  const [addCompanyCoupon, setAddCompanyCoupon] = useState(null);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");

  const tableColumns =
    tableView === "offsetSubs"
      ? [
          makeColumnObj("NAME/ID", "name", true),
          makeColumnObj("TOTAL BALANCE"),
          makeColumnObj("LAST MONTHS EMISSIONS"),
          makeColumnObj("AVG MONTHLY EMISSIONS"),
          makeColumnObj("OFFSET PREFERENCES"),
          makeColumnObj("LAST OFFSET PURCHASED"),
          makeColumnObj("ACTIONS"),
        ]
      : [
          makeColumnObj("NAME/ID", "name", true),
          makeColumnObj("USERS", "userCount", true),
          makeColumnObj("CLIMATE LEADER PAGE", "isCertified", true),
          makeColumnObj("VIEW"),
          makeColumnObj("ACTIONS"),
        ];

  const formattedCompanies = companies.map((company) => {
    const { certification, uids, name } = company;

    const isCertified = !!certification;
    const userCount = uids?.length || 0;
    return { ...company, name: ucFirstLetters(name), isCertified, userCount };
  });

  const sortCompanies = () => {
    const sortedCompanies = [...formattedCompanies].sort((a, b) => {
      if (a[orderBy] > b[orderBy]) {
        return -1;
      }

      if (a[orderBy] < b[orderBy]) {
        return 1;
      }

      return 0;
    });

    if (order === "asc") {
      return [...sortedCompanies].reverse();
    }

    return sortedCompanies;
  };

  const sortedCompanies = sortCompanies();

  return (
    <>
      {offsetSubscriptionTag && (
        <OffsetSubscriptionTag
          setOffsetSubscriptionTag={setOffsetSubscriptionTag}
          {...offsetSubscriptionTag}
        />
      )}
      {purchaseOffsetsForCompany && (
        <PurchaseOffsetsForCompany
          setPurchaseOffsetsForCompany={setPurchaseOffsetsForCompany}
          {...purchaseOffsetsForCompany}
        />
      )}
      {addCompanyCoupon && (
        <AddCompanyCoupon
          setAddCompanyCoupon={setAddCompanyCoupon}
          {...addCompanyCoupon}
        />
      )}
      <SortableTable
        size="small"
        rows={sortedCompanies}
        columns={tableColumns}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rowComponent={(row, idx) => (
          <>
            {tableView === "normal" ? (
              <AccountRow
                key={`account-row-${idx}`}
                company={row}
                setDialogOpen={setDialogOpen}
                setOffsetSubscriptionTag={setOffsetSubscriptionTag}
                setPurchaseOffsetsForCompany={setPurchaseOffsetsForCompany}
                tableView={tableView}
                setAddCompanyCoupon={setAddCompanyCoupon}
              />
            ) : (
              <OffsetSubAccountRow
                key={`offset-sub-account-row-${idx}`}
                company={row}
                setPurchaseOffsetsForCompany={setPurchaseOffsetsForCompany}
              />
            )}
          </>
        )}
      />
    </>
  );
};

const CompanyDetailsDialog = ({ companyId, setDetailsDialogOpen }) => {
  const [
    {
      name,
      averageMonthlyRecurringEmissionsTons,
      currentCarbonBalanceTons,
      certificationDate,
      certificationLevel,
      employeeCount,
    },
    companyDetailsLoading,
  ] = useCompanyDetails(companyId);

  return (
    <Dialog open onClose={() => setDetailsDialogOpen(null)}>
      {companyDetailsLoading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>{`${name}`}</DialogTitle>
          <DialogContent>
            <Typography
              variant="subtitle1"
              gutterBottom
            >{`${employeeCount} employees`}</Typography>
            <Typography variant="subtitle1" gutterBottom>{`${tonsToLbs(
              averageMonthlyRecurringEmissionsTons
            )?.toFixed(2)} recurring lbs of carbon monthly`}</Typography>
            <Typography variant="subtitle1" gutterBottom>{`${tonsToLbs(
              currentCarbonBalanceTons
            )?.toFixed(2)} lbs current carbon balance`}</Typography>
            <Typography variant="subtitle1" gutterBottom>
              {certificationDate && certificationLevel
                ? `Climate Leader ${certificationLevel} until ${dayjs(
                    certificationDate
                  ).format("MM-DD-YY")}`
                : "Not a climate leader"}
            </Typography>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const CompanySearch = ({ accountsData, setFilteredAccountsData }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchValueChange = (value) => {
    setSearchValue(value);

    if (searchValue !== "") {
      const matchString = (string = "") =>
        string.toString().toLowerCase().includes(searchValue.toLowerCase());

      return setFilteredAccountsData(
        accountsData.filter(({ name = "", users = [] }) => {
          const nameMatch = name
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase());

          const usersMatch = !!users.find((user) => matchString(user?.name));

          return nameMatch || usersMatch;
        })
      );
    }

    return setFilteredAccountsData(accountsData);
  };

  return (
    <TextField
      label="Search for a company by name"
      value={searchValue}
      setValue={handleSearchValueChange}
      style={{ width: "100%" }}
    />
  );
};

const Accounts = () => {
  const [user, userLoading] = useAuth();
  const [accountsData, setAccountsData] = useState([]);
  const [filteredAccountsData, setFilteredAccountsData] = useState([]);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(null);
  const [tableView, setTableView] = useState("normal");

  useEffect(() => {
    if (user && !userLoading) {
      apiFetch({
        user,
        path: "/companies/fetch-companies",
        method: "GET",
        callback: (res) => {
          setFilteredAccountsData(res);
          return setAccountsData(res);
        },
      });
    }
  }, [user, userLoading]);

  const offsetSubsAccountData = accountsData.filter(({ billing = {} }) => {
    const { subscriptions = [] } = billing;

    if (!Array.isArray(subscriptions)) {
      return false;
    }

    return subscriptions?.includes("offsetSubscription");
  });

  return (
    <Layout title="Account Data">
      {detailsDialogOpen && (
        <CompanyDetailsDialog
          companyId={detailsDialogOpen}
          setDetailsDialogOpen={setDetailsDialogOpen}
        />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item container xs={8} direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">{`Count: ${accountsData.length}`}</Typography>
            </Grid>
            <Grid item>
              <ToggleButtons
                value={tableView}
                onChange={setTableView}
                buttons={[
                  { name: "Normal", value: "normal" },
                  { name: "Offset Subs", value: "offsetSubs" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <CompanySearch
              accountsData={accountsData}
              setFilteredAccountsData={setFilteredAccountsData}
            />
          </Grid>
        </Grid>
        <Grid item>
          <AccountsTable
            companies={
              tableView === "normal"
                ? filteredAccountsData
                : offsetSubsAccountData
            }
            setDialogOpen={setDetailsDialogOpen}
            tableView={tableView}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};
export default Accounts;
