import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  Popover,
} from "@material-ui/core";

import { apiFetch } from "../../helpers/apiFetch";
import TextField from "../atoms/TextField";
import LoadingButton from "../atoms/LoadingButton";
import SortableTable, { makeColumnObj } from "../modules/SortableTable";
import DialogBox from "../atoms/DialogBox";
import {
  useAuth,
  useCachedCollectionData,
  updateCollectionDoc,
  useCollectionDoc,
  deleteFirebaseDoc,
} from "../../helpers/firebase";

const KeywordDialogBox = ({
  keywordDialogOpen,
  setKeywordDialogOpen,
  selectedKeyword,
}) => {
  const { id, names = [] } = selectedKeyword || {};
  const [selectedName, setSelectedName] = useState("");

  const onKeywordSave = () => {
    setKeywordDialogOpen(false);
    return updateCollectionDoc(`/monitorCategoryChanges/${id}`, {
      names: [selectedName, ...names],
    });
  };

  return (
    <>
      {keywordDialogOpen && (
        <DialogBox
          open={keywordDialogOpen}
          setOpen={setKeywordDialogOpen}
          contentBlock={
            <Grid container direction="column" spacing={3}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">
                    Change keyword: "{names[0]}"?
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <TextField
                    label="Keyword"
                    setValue={setSelectedName}
                    value={selectedName}
                  />
                </Grid>
              </Grid>
              {!!selectedName.length && (
                <Grid item container justifyContent="center">
                  <Grid item>
                    <Button variant="contained" onClick={() => onKeywordSave()}>
                      Select Keyword
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
          maxWidth="lg"
          fullWidth
        />
      )}
    </>
  );
};

const AlgorithmInfoRow = ({ algorithmInfos }) =>
  algorithmInfos.map(
    ({
      originalCategory: originalCat,
      rule,
      rulesVersion,
      matchScore,
      matchedWord,
      emissionProbability,
    }) => {
      const originalCategory = originalCat || "none";
      const algorithmProperties = [
        { key: "Original Category: ", value: originalCategory },
        { key: "Emission Probability: ", value: emissionProbability },
        { key: "Rule: ", value: rule },
        { key: "Rules Version: ", value: rulesVersion },
        { key: "Matched Word: ", value: matchedWord },
        { key: "Match Score: ", value: matchScore },
      ];

      return (
        <Box m={1} pt={1}>
          {algorithmProperties.map(({ key, value }) => (
            <div style={{ display: "flex" }}>
              <Typography style={{ fontWeight: "bold", whiteSpace: "pre" }}>
                {key}
              </Typography>
              <Typography> {value}</Typography>
            </div>
          ))}
        </Box>
      );
    }
  );

const KeywordsRow = ({
  keywordChanges,
  setSelectedKeyword,
  setKeywordDialogOpen,
  onAcceptAllButtonClick,
  onRejectButtonClick,
}) => {
  const onKeywordClick = (keyword) => {
    setSelectedKeyword(keyword);
    setKeywordDialogOpen(true);
  };

  const [anchorNames, setAnchorNames] = useState(null);
  const [anchorAlgo, setAnchorAlgo] = useState(null);
  const [hover, setHover] = useState(false);

  if (keywordChanges.lineBreak) {
    return (
      <TableRow>
        <TableCell colSpan={8} style={{ fontWeight: "bold" }}>
          Keyword
        </TableCell>
      </TableRow>
    );
  }

  const {
    transactionInfos,
    count,
    toCategory,
    previousMatch,
    name,
    names,
    id,
    index,
    length,
  } = keywordChanges;

  const algorithmInfos = transactionInfos.map(
    ({ algorithmInfo }) => algorithmInfo
  );

  const getOldToCategory = (previousMatch) => {
    if (!previousMatch) {
      return "DNE";
    }

    if (!previousMatch.length) {
      return "DNE";
    }

    const [{ toCategory: oldToCategory }] = previousMatch;
    return `Accepted as ${oldToCategory}`;
  };

  return (
    <TableRow>
      <TableCell
        style={{
          width: "150px",
          color: hover ? "#0000FF" : "",
          textDecoration: hover ? "underline" : "",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => onKeywordClick({ names, id })}
      >
        {index === 0 ? name : ""}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        {getOldToCategory(previousMatch)}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
          color: toCategory === "none" ? "#f44336" : "",
          fontWeight: toCategory === "none" ? "bold" : "",
        }}
      >
        {toCategory}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        {index === 0 && (
          <Button
            variant="contained"
            color="primary"
            disabled={length !== 1}
            onClick={() => {
              onAcceptAllButtonClick(name, toCategory, previousMatch);
            }}
          >
            Accept All
          </Button>
        )}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        <div>
          <Button
            variant="contained"
            onClick={(e) =>
              !!anchorAlgo
                ? setAnchorAlgo(null)
                : setAnchorAlgo(e.currentTarget)
            }
          >
            Display Algorithm Info
          </Button>
          <Popover
            open={!!anchorAlgo}
            anchorEl={anchorAlgo}
            onClose={(e) => setAnchorAlgo(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <AlgorithmInfoRow algorithmInfos={algorithmInfos} />
          </Popover>
        </div>
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        {count}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        {transactionInfos.length > 1 ? (
          <div>
            <Button
              variant="contained"
              onClick={(e) =>
                !!anchorNames
                  ? setAnchorNames(null)
                  : setAnchorNames(e.currentTarget)
              }
            >
              Display Companies
            </Button>
            <Popover
              open={!!anchorNames}
              anchorEl={anchorNames}
              onClose={(e) => setAnchorNames(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {transactionInfos.map((info) => (
                <Box m={2} pt={1}>
                  <Typography>{info.companyName}</Typography>
                </Box>
              ))}
            </Popover>
          </div>
        ) : (
          transactionInfos[0].companyName
        )}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => {
            onRejectButtonClick(name, toCategory, transactionInfos);
          }}
        >
          Reject
        </Button>
      </TableCell>
    </TableRow>
  );
};

const FuzzyMatchTableRow = ({
  setButtonDialogOpen,
  keywords,
  setActionButton,
  setSelectedTran,
}) => {
  const onAcceptMatchButtonClick = (name) => {
    setButtonDialogOpen(true);
    setActionButton("acceptMatch");
    setSelectedTran({ name });
  };

  const onRejectMatchButtonClick = (name) => {
    setButtonDialogOpen(true);
    setActionButton("rejectMatch");
    setSelectedTran({ name });
  };

  const { potentialMatch, names } = keywords;
  const [name] = names;
  return (
    <TableRow>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        {name}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        {potentialMatch}
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onAcceptMatchButtonClick(name);
          }}
        >
          Accept Match
        </Button>
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => {
            onRejectMatchButtonClick(name);
          }}
        >
          Reject Match
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ButtonDialog = ({
  buttonDialogOpen,
  setButtonDialogOpen,
  actionButton,
  selectedTran,
}) => {
  const { toCategory, name } = selectedTran || { name: "" };
  const [user] = useAuth();
  const [loading, setLoading] = useState(false);
  const [documentKeyword] = useCachedCollectionData("monitorCategoryChanges", [
    ["names", "array-contains", name],
  ]);
  const {
    id: keywordDocId,
    changes = [],
    names = [],
    potentialMatch = "",
    rejectedChanges = [],
    acceptedChanges = [],
  } = documentKeyword || {};

  const [matchKeyword] = useCachedCollectionData("monitorCategoryChanges", [
    ["names", "array-contains", potentialMatch],
  ]);
  const { names: matchedKeywordNames, id: matchDocId } = matchKeyword || {};

  const remainingChanges = documentKeyword
    ? changes.filter(
        ({ toCategory: dbToCategory }) => toCategory !== dbToCategory
      )
    : [];
  const currentChange = changes
    ? changes.find(
        ({ toCategory: dbToCategory }) => dbToCategory === toCategory
      )
    : [];
  const [documentVendors] = useCollectionDoc("transactionAlgorithm/vendors");

  const addGlobalChange = async () => {
    const updatedVendors = Object.entries(documentVendors).map(
      ([key, values]) => {
        const updatedValues = acceptedChanges?.length
          ? values.filter(
              (value) => !names.some((name) => value.includes(name))
            )
          : values;

        const formattedNames = names.map((name) => {
          if (toCategory === "gas") {
            return `${name} gas`;
          }
          if (toCategory === "electricity") {
            return `${name} electric`;
          }
          return name;
        });

        if (
          key === "utilities" &&
          (toCategory === "gas" || toCategory === "electricity")
        ) {
          return [key, [...updatedValues, ...formattedNames]];
        }

        if (key === "excluded" && toCategory === "none") {
          return [key, [...updatedValues, ...formattedNames]];
        }

        if (key !== toCategory) {
          return [key, [...updatedValues]];
        }

        return [key, [...updatedValues, ...formattedNames]];
      }
    );

    const updatedVendorsObj = Object.assign(
      ...updatedVendors.map(([key, val]) => ({ [key]: val }))
    );

    return await Promise.all([
      updateCollectionDoc("transactionAlgorithm/vendors", updatedVendorsObj),
      updateCollectionDoc(`monitorCategoryChanges/${keywordDocId}`, {
        changes: [],
        rejectedChanges: [...rejectedChanges, ...acceptedChanges],
        acceptedChanges: changes,
      }),
    ]);
  };

  const rejectChange = async () =>
    await updateCollectionDoc(`monitorCategoryChanges/${keywordDocId}`, {
      changes: remainingChanges,
      rejectedChanges: [...rejectedChanges, currentChange],
    });

  const rejectMatch = async () =>
    await updateCollectionDoc(`monitorCategoryChanges/${keywordDocId}`, {
      potentialMatch: null,
    });

  const acceptMatch = async () => {
    const keywordInfos = changes
      .map((change) =>
        change.transactionInfos.map((_, index) => {
          const { companyName, companyId, transactionId, algorithmInfo } =
            change.transactionInfos[index];

          return {
            keyword: name,
            toCategory: change.toCategory,
            algorithmInfo,
            currentSubcategory: null,
            transactionInfo: {
              companyName,
              companyId,
              transactionId,
            },
          };
        })
      )
      .flat();

    deleteFirebaseDoc("monitorCategoryChanges", keywordDocId);

    //update keywords in monitorCategoryChanges to reflect the new match
    return await updateCollectionDoc(`monitorCategoryChanges/${matchDocId}`, {
      names: [...matchedKeywordNames, name],
    }).then(() =>
      Promise.all(
        keywordInfos.map(
          async (keywordInfo) =>
            await apiFetch({
              user,
              method: "POST",
              path: "/keywords/update-keywords",
              data: { keywordInfo, matchedKeyword: name },
            })
        )
      )
    );
  };

  const getButtonInfo = () => {
    if (actionButton === "all") {
      return {
        label: "Accept",
        dialog: `Accept keyword "${name}" as a(n) "${toCategory.toUpperCase()}"
      transaction for ALL companies?`,
      };
    }

    if (actionButton === "reject") {
      return { label: "Reject", dialog: "Reject this change" };
    }

    return {
      label: "Confirm",
      dialog: `${name} and ${potentialMatch}
    ARE ${actionButton === "rejectMatch" ? ` NOT ` : ""}
matching keywords?`,
    };
  };

  const { label, dialog } = getButtonInfo();

  const buttonFunction = async () => {
    setLoading(true);
    if (actionButton === "all") {
      await addGlobalChange();
    }

    if (actionButton === "reject") {
      await rejectChange();
    }

    if (actionButton === "rejectMatch") {
      await rejectMatch();
    }

    if (actionButton === "acceptMatch") {
      await acceptMatch(potentialMatch);
    }

    setLoading(false);
    return setButtonDialogOpen(false);
  };

  return (
    <>
      <Dialog open={buttonDialogOpen}>
        <DialogTitle>{dialog}</DialogTitle>
        <DialogActions>
          <LoadingButton
            isLoading={loading}
            onClick={buttonFunction}
            label={label}
            color="primary"
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setButtonDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Keywords = () => {
  const allKeywords = useCachedCollectionData("monitorCategoryChanges");
  const [buttonDialogOpen, setButtonDialogOpen] = useState(false);
  const [actionButton, setActionButton] = useState(null);
  const [selectedTran, setSelectedTran] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [keywordDialogOpen, setKeywordDialogOpen] = useState(false);
  const [displayRules, setDisplayRules] = useState(false);
  const [rules, setRules] = useState("");
  const [user] = useAuth();

  useEffect(() => {
    if (user && !rules.length) {
      apiFetch({
        user,
        method: "POST",
        path: "/keywords/fetch-rules",
        callback: (res) => setRules(res),
      });
    }
  }, [user, rules]);

  const tableColumns = [
    makeColumnObj("Keyword", "keyword"),
    makeColumnObj("Existing Global Change", "review"),
    makeColumnObj("New Category", "newCategory"),
    makeColumnObj("Global Change", "globalChange"),
    makeColumnObj("Algorithm Info", "algorithmInfo"),
    makeColumnObj("Count", "count", true),
    makeColumnObj("Company Names", "userIds"),
    makeColumnObj("Reject", "reject"),
  ];

  const fuzzyMatchColumns = [
    makeColumnObj("Keyword Matching", "keyword"),
    makeColumnObj("Matched Keyword", "match"),
    makeColumnObj("Accept", "accept"),
    makeColumnObj("Reject", "reject"),
  ];

  const keywords = allKeywords.filter((keyword) => {
    return keyword.changes?.length && !keyword.potentialMatch;
  });

  const possibleMatches = allKeywords.filter((keyword) => {
    return keyword.changes?.length && keyword.potentialMatch;
  });

  const onAcceptAllButtonClick = (name, toCategory, previousMatch) => {
    setButtonDialogOpen(true);
    setActionButton("all");
    setSelectedTran({ name, toCategory, previousMatch });
  };

  const onRejectButtonClick = (name, toCategory, id) => {
    setButtonDialogOpen(true);
    setActionButton("reject");
    setSelectedTran({
      name,
      toCategory,
      id,
    });
  };

  const keyChanges = keywords
    .map(({ names, changes, acceptedChanges, id }, outerIndex) =>
      changes.map((change, index) => [
        {
          ...change,
          length: changes.length,
          name: names[0],
          names,
          id,
          previousMatch: acceptedChanges,
          index,
        },
        index === changes.length - 1 &&
          outerIndex !== keywords.length - 1 && { lineBreak: true },
      ])
    )
    .flat();

  const keywordChanges = keyChanges.flat().filter((e) => e);

  const ShowRules = () => {
    if (displayRules) {
      return (
        <Typography style={{ fontSize: "10pt", paddingLeft: "20px" }}>
          {parse(rules)}
        </Typography>
      );
    }
    return <></>;
  };

  return (
    <>
      <div style={{ paddingBottom: "10px", paddingLeft: "20px" }}>
        <Button
          onClick={() => setDisplayRules((prevState) => !prevState)}
          variant="contained"
          size="small"
          style={{ width: "300px" }}
        >
          Rules
        </Button>
        {displayRules && <ShowRules />}
      </div>
      <ButtonDialog
        buttonDialogOpen={buttonDialogOpen}
        setButtonDialogOpen={setButtonDialogOpen}
        actionButton={actionButton}
        selectedTran={selectedTran}
      />
      <KeywordDialogBox
        selectedKeyword={selectedKeyword}
        keywordDialogOpen={keywordDialogOpen}
        setKeywordDialogOpen={setKeywordDialogOpen}
      />
      <SortableTable
        size="small"
        rows={possibleMatches}
        style={{ fontSize: "16pt" }}
        columns={fuzzyMatchColumns}
        rowComponent={(row) => (
          <FuzzyMatchTableRow
            keywords={row}
            setButtonDialogOpen={setButtonDialogOpen}
            setActionButton={setActionButton}
            setSelectedTran={setSelectedTran}
          />
        )}
      />
      <SortableTable
        columnFont="large"
        size="small"
        style={{ fontSize: "14pt", paddingTop: "100px", paddingBottom: "20px" }}
        rows={keywordChanges}
        columns={tableColumns}
        rowComponent={(row) => (
          <KeywordsRow
            keywordChanges={row}
            setSelectedKeyword={setSelectedKeyword}
            setKeywordDialogOpen={setKeywordDialogOpen}
            onAcceptAllButtonClick={onAcceptAllButtonClick}
            onRejectButtonClick={onRejectButtonClick}
          />
        )}
      />
    </>
  );
};
export default Keywords;
