import React from "react";

import { Grid, Card, CardHeader, IconButton } from "@material-ui/core";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Layout from "./Layout";

const pages = [
  {
    title: "Offset Projects",
    subtitle: "Pull and edit Cloverly Projects",
    link: "/projects",
  },
  {
    title: "Algorithm Keywords",
    subtitle: "Review and edit algorithm keywords",
    link: "/keywords",
  },
  {
    title: "User Accounts",
    subtitle: "Browse through user account data",
    link: "/accounts",
  },
];

const ButtonCards = ({ title, subtitle, link }) => (
  <Card>
    <CardHeader
      title={title}
      subheader={subtitle}
      action={
        <IconButton href={link}>
          <ExitToAppIcon />
        </IconButton>
      }
    />
  </Card>
);

const Home = () => {
  return (
    <Layout title="Home">
      <Grid container spacing={2}>
        {pages.map((card, idx) => (
          <Grid item sm={4} key={`page-card-${idx}`}>
            <ButtonCards {...card} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};
export default Home;
