import { useState, useEffect } from "react";
import firebase from "firebase";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import useSWR from "swr";
import { useAuthState } from "react-firebase-hooks/auth";

const {
  REACT_APP_FIREBASE_API_KEY: apiKey,
  REACT_APP_FIREBASE_APP_ID: appId,
  REACT_APP_FIREBASE_AUTH_DOMAIN: authDomain,
  REACT_APP_FIREBASE_DB_URL: databaseURL,
  REACT_APP_FIREBASE_MEASUREMENT_ID: measurementId,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
  REACT_APP_FIREBASE_PROJECT_ID: projectId,
  REACT_APP_FIREBASE_STORAGE_BUCKET: storageBucket,
} = process.env;

const config = {
  apiKey,
  appId,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  measurementId,
};

if (typeof window !== "undefined" && !firebase.apps.length) {
  firebase.initializeApp(config);
}

export const signOut = async () => await firebase.auth().signOut();

const dateToFirebaseTimeStamp = (date = null) => {
  const adjustedDate = dayjs(date).add(1, "day");
  return firebase.firestore.Timestamp.fromDate(adjustedDate.toDate());
};

export const firebaseArrayUnionObj = (data) => ({ data, arrayUnion: true });

export const firebaseArrayUnion = (value) =>
  firebase.firestore.FieldValue.arrayUnion(value);

export const firebaseArrayRemoveObj = (data) => ({ data, arrayRemove: true });

export const firebaseArrayRemove = (value) =>
  firebase.firestore.FieldValue.arrayRemove(value);

const db = firebase.firestore();
export const auth = firebase.auth();

export const useAuth = () => useAuthState(firebase.auth());

export const prepareFirebaseObj = (obj) =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => !(value === undefined))
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return [
            key,
            value.map((row) =>
              typeof row === "string" || typeof row === "number"
                ? row
                : prepareFirebaseObj(row)
            ),
          ];
        }

        if (
          typeof key === "string" &&
          typeof value === "string" &&
          RegExp(/^\d{1,2}(\/|-)\d{1,2}(\/|-)(?:\d{4}|\d{2})$/, "g").test(
            value
          ) &&
          !isEmpty(value)
        ) {
          return [key, dateToFirebaseTimeStamp(new Date(value))];
        }

        if (value && typeof value === "object") {
          if (Object.prototype.toString.call(value) === "[object Date]") {
            //this is a weird hack, because for some reason firebase adds one day to timestamps before storing them.
            const modifiedDate = dayjs(value).subtract(1, "day");
            return [key, dateToFirebaseTimeStamp(modifiedDate.toDate())];
          }

          const { seconds, data, arrayUnion, arrayRemove } = value;

          if (seconds) {
            return [key, value];
          }

          if (arrayUnion && data && Array.isArray(data)) {
            return [key, firebaseArrayUnion(...data)];
          }
          if (arrayUnion && data) {
            return [key, firebaseArrayUnion(data)];
          }

          if (arrayRemove && data) {
            return [key, firebaseArrayRemove(data)];
          }

          return [key, prepareFirebaseObj(value)];
        }

        return [key, value];
      })
  );

const unpackFirebaseObj = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [
          key,
          value.map((row) =>
            typeof row === "string" || typeof row === "number"
              ? row
              : unpackFirebaseObj(row)
          ),
        ];
      }
      if (value && typeof value === "object") {
        const { seconds } = value || {};
        if (seconds) {
          return [key, new Date(seconds * 1000)];
        }
        return [key, unpackFirebaseObj(value)];
      }
      return [key, value];
    })
  );

export const useDestructuredSWRdata = (queryCache, fetcher) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { data: rawData, error: rawError } = useSWR(queryCache, fetcher);

  useEffect(() => {
    if (!rawError && rawData) {
      setData(rawData);
      setLoading(false);
    }
    if (rawError) {
      setError(rawError);
      setLoading(false);
    }
  }, [rawData, rawError]);

  return [data, loading, error];
};

const compoundFirebaseQueries = (ref, queries) => {
  const queryCount = queries.length;
  if (!queryCount) {
    return ref;
  }
  if (queryCount === 1) {
    return ref.where(...queries[0]);
  }
  if (queryCount === 2) {
    return ref.where(...queries[0]).where(...queries[1]);
  }
  if (queryCount === 3) {
    return ref
      .where(...queries[0])
      .where(...queries[1])
      .where(...queries[2]);
  }
};

const fetchFirebaseQueryWithIds = async (path, queries = []) => {
  const collectionRef = db.collection(path);
  const firebaseQuery = compoundFirebaseQueries(collectionRef, queries);

  return await firebaseQuery.onSnapshot((snapshot) =>
    snapshot.docs.map((doc) => {
      const docData = doc.data();
      return { ...unpackFirebaseObj(docData), id: doc.id };
    })
  );
};

const useFirebaseQueryWithIds = (path, queries = []) => {
  const [data, setData] = useState([]);
  const collectionRef = db.collection(path);

  useEffect(() => {
    const firebaseQuery = compoundFirebaseQueries(collectionRef, queries);

    const unsubscribe = firebaseQuery.onSnapshot((snapshot) => {
      const dbData = snapshot.docs.map((doc) => {
        const docData = doc.data();
        return { ...unpackFirebaseObj(docData), id: doc.id };
      });

      if (!data.length) {
        setData(dbData);
      }
    });

    return () => unsubscribe();
  }, [collectionRef, queries]);

  return data;
};

export const useUncachedCollectionData = (collection, queries = []) => {
  const [dbData, setDbData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (dataLoading && !dbData.length) {
      const collectionRef = db.collection(`${collection}`);
      const modifiedQueries =
        collection === "employees" ||
        collection === "vehicles" ||
        collection === "offices"
          ? [["archived", "==", false], ...queries]
          : queries;

      const firebaseQuery = compoundFirebaseQueries(
        collectionRef,
        modifiedQueries
      );

      const unsubscribe = firebaseQuery.onSnapshot((snap) => {
        const data = snap.docs.map((doc) => ({
          ...unpackFirebaseObj(doc.data()),
          id: doc.id,
        }));
        setDbData(data);
        setDataLoading(false);
      });
      return () => unsubscribe();
    }
  }, [collection, queries, dbData.length, dataLoading]);

  return [dbData, dataLoading];
};

export const useCachedCollectionData = (path, queries) =>
  useFirebaseQueryWithIds(path, queries);

export const deleteFirebaseDoc = async (path, doc) => {
  return await db.collection(path).doc(doc).delete();
};

export const useCollectionDoc = (path) => {
  const fetchDocData = () =>
    db
      .doc(path)
      .get()
      .then((doc) => unpackFirebaseObj(doc.data()));

  return useDestructuredSWRdata(path, () => fetchDocData());
};

export const updateCollectionDoc = async (path, updateObj) => {
  return await db
    .doc(path)
    .update(prepareFirebaseObj(updateObj))
    .catch((e) => {
      console.log("firebase update error: " + JSON.stringify(e));
    });
};
