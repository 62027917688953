import React, { useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Button,
  TableRow,
  TableCell,
  InputAdornment,
  Card,
  CardMedia,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Layout from "./Layout";
import DialogBox from "./atoms/DialogBox";
import TextField from "./atoms/TextField";
import SortableTable, { makeColumnObj } from "./modules/SortableTable";

import {
  updateCollectionDoc,
  useCachedCollectionData,
  useAuth,
} from "../helpers/firebase";
import { apiFetch } from "../helpers/apiFetch";
import { formatDecimal, formatDate } from "../helpers/formatters";
import { kgsToLbs } from "../helpers/converters";
import { useEffect } from "react";

const ProjectRows = ({
  project: {
    image,
    name,
    offsetType,
    availableCarbonInKg,
    costPerThousandLbs,
    id,
    verified,
    verificationRegistry,
    offsetProvider,
  },
  onSelectImage,
  onVerifyClick,
}) => (
  <TableRow>
    {image ? (
      <TableCell
        style={{
          width: "150px",
          height: "100px",
          backgroundImage: `url('${image}')`,
          backgroundSize: "cover",
        }}
      >
        <Grid container justifyContent="center">
          <Grid item>
            <Tooltip title="Search for another image">
              <IconButton size="small" onClick={() => onSelectImage(id)}>
                <ImageSearchIcon style={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="View Image">
              <IconButton size="small" href={image}>
                <VisibilityIcon style={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    ) : (
      <TableCell>
        <Button
          variant="contained"
          size="small"
          startIcon={<WarningIcon />}
          onClick={() => onSelectImage(id)}
        >
          Select image
        </Button>
      </TableCell>
    )}
    <TableCell>{offsetProvider}</TableCell>
    {verified ? (
      <TableCell onClick={() => onVerifyClick(id)}>
        {verificationRegistry}
      </TableCell>
    ) : (
      <TableCell>
        <Button
          variant="contained"
          size="small"
          startIcon={<WarningIcon />}
          onClick={() => onVerifyClick(id)}
        >
          Verify Project
        </Button>
      </TableCell>
    )}

    <TableCell>{name}</TableCell>
    <TableCell>{offsetType}</TableCell>
    <TableCell>{costPerThousandLbs}</TableCell>
    <TableCell>
      {formatDecimal(Math.round(kgsToLbs(availableCarbonInKg)))}
    </TableCell>
  </TableRow>
);

const ProjectsTable = ({
  setImageDialogOpen,
  setVerifyDialogOpen,
  setSelectedProject,
}) => {
  const projects = useCachedCollectionData("projects", [
    ["isActive", "==", true],
  ]);

  const tableColumns = [
    makeColumnObj("IMAGE", "image"),
    makeColumnObj("SOURCE", "source"),
    makeColumnObj("VERIFIED", "verified"),
    makeColumnObj("NAME", "name", true),
    makeColumnObj("TYPE", "offsetType", true),
    makeColumnObj("PRICE PER 1K LBS.", "costPerThousandLbs", true),
    makeColumnObj("LBS. AVAILABLE", "availableCarbonInKg", true),
  ];

  const onSelectImage = (id) => {
    setImageDialogOpen(true);
    setSelectedProject(id);
  };

  const onVerifyClick = (id) => {
    setVerifyDialogOpen(true);
    setSelectedProject(id);
  };

  return (
    <SortableTable
      size="small"
      rows={projects.sort((a, b) => {
        const firstValue = a.costPerThousandLbs;
        const secondValue = b.costPerThousandLbs;
        if (!isNaN(firstValue) || !isNaN(secondValue)) {
          return firstValue - secondValue;
        }
        return -1;
      })}
      columns={tableColumns}
      rowComponent={(row, idx) => (
        <ProjectRows
          key={`project-row-${idx}`}
          project={row}
          onSelectImage={onSelectImage}
          onVerifyClick={onVerifyClick}
        />
      )}
    />
  );
};

const ImageCard = ({ image = {}, idx, selectedImg, setSelectedImg }) => {
  return (
    <Card onClick={() => setSelectedImg(idx)}>
      <CardMedia
        style={{
          paddingTop: "56.25%",
          backgroundSize: "cover",
          opacity: idx === selectedImg ? 1 : 0.6,
        }}
        image={image.urls?.small}
      />
    </Card>
  );
};

const ImagesDialogBox = ({
  dialogImageOpen,
  setImageDialogOpen,
  selectedProject,
}) => {
  const [selectedUrl, setSelectedUrl] = useState("");
  const [user] = useAuth();
  const [imageSearchString, setImageSearchString] = useState("");
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);

  const fetchImages = () =>
    apiFetch({
      user,
      path: `/unsplash/${imageSearchString}`,
      method: "GET",
      callback: ({ results }) => setAvailableImages(results),
    });

  const onImgSave = () => {
    setImageDialogOpen(false);
    return updateCollectionDoc(`/projects/${selectedProject}`, {
      image: availableImages[selectedImg].urls.regular,
    });
  };

  const onImageUrlSave = () => {
    setImageDialogOpen(false);
    return updateCollectionDoc(`/projects/${selectedProject}`, {
      image: selectedUrl,
    });
  };

  return (
    <>
      {dialogImageOpen && (
        <DialogBox
          open={dialogImageOpen}
          setOpen={setImageDialogOpen}
          contentBlock={
            <Grid container direction="column" spacing={3}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="right"
              >
                <Grid item>
                  <Typography variant="h6">
                    Find an image or use a URL
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    label="Find an Image"
                    value={imageSearchString}
                    bv
                    setValue={setImageSearchString}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => fetchImages()}
                            disabled={!imageSearchString}
                          >
                            Search
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    label="Choose URL"
                    value={selectedUrl}
                    setValue={setSelectedUrl}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => onImageUrlSave()}
                            disabled={!selectedUrl}
                          >
                            Select
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                {availableImages.map((image, idx) => (
                  <Grid item sm={3} key={`unsplash-image-${idx}`}>
                    <ImageCard
                      image={image}
                      idx={idx}
                      selectedImg={selectedImg}
                      setSelectedImg={setSelectedImg}
                    />
                  </Grid>
                ))}
              </Grid>
              {!!availableImages.length && (
                <Grid item container justifyContent="center">
                  <Grid item>
                    <Button variant="contained" onClick={() => onImgSave()}>
                      Select Image
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
          maxWidth="lg"
          fullWidth
        />
      )}
    </>
  );
};

const VerifyDialogBox = ({
  dialogVerifyOpen,
  setVerifyDialogOpen,
  selectedProject,
}) => {
  const [selectedUrl, setSelectedUrl] = useState("");

  const onVerifySave = () => {
    setVerifyDialogOpen(false);
    return updateCollectionDoc(`/projects/${selectedProject}`, {
      verified: true,
      verificationUrl: selectedUrl,
    });
  };

  return (
    <>
      {dialogVerifyOpen && (
        <DialogBox
          open={dialogVerifyOpen}
          setOpen={setVerifyDialogOpen}
          contentBlock={
            <Grid container direction="column" spacing={3}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">Verify this project</Typography>
                </Grid>
                <Grid item sm={5}>
                  <TextField
                    label="Verification URL"
                    value={selectedUrl}
                    setValue={setSelectedUrl}
                  />
                </Grid>
              </Grid>
              {!!selectedUrl.length && (
                <Grid item container justifyContent="center">
                  <Grid item>
                    <Button variant="contained" onClick={() => onVerifySave()}>
                      Select Verification
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
          maxWidth="lg"
          fullWidth
        />
      )}
    </>
  );
};

const Projects = () => {
  const [user] = useAuth();
  const projects = useCachedCollectionData("projects", [
    ["isActive", "==", true],
  ]);

  const [fetchDate, setFetchDate] = useState(new Date());
  const [dialogImageOpen, setImageDialogOpen] = useState(false);
  const [dialogVerifyOpen, setVerifyDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    if (projects.length) {
      const latestDate = projects.reduce(
        (lateDate, { fetchDate }) =>
          fetchDate > lateDate ? fetchDate : lateDate,
        dayjs().subtract(1, "month").toDate()
      );
      setFetchDate(latestDate);
    }
  }, [projects]);

  return (
    <>
      <ImagesDialogBox
        dialogImageOpen={dialogImageOpen}
        setImageDialogOpen={setImageDialogOpen}
        selectedProject={selectedProject}
      />
      <VerifyDialogBox
        dialogVerifyOpen={dialogVerifyOpen}
        setVerifyDialogOpen={setVerifyDialogOpen}
        selectedProject={selectedProject}
      />
      <Layout title="Projects">
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">{`Projects- last fetched ${formatDate(
                fetchDate
              )}`}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  apiFetch({
                    user,
                    method: "POST",
                    path: "/cloverly/fetch-offsets",
                  });
                  apiFetch({
                    user,
                    method: "POST",
                    path: "/patch/fetch-offsets",
                  });
                  apiFetch({
                    user,
                    method: "GET",
                    path: "/climeco/fetch-offsets",
                  });
                }}
              >
                Fetch Projects
              </Button>
            </Grid>
          </Grid>
          <Grid item container>
            <ProjectsTable
              setImageDialogOpen={setImageDialogOpen}
              setVerifyDialogOpen={setVerifyDialogOpen}
              setSelectedProject={setSelectedProject}
            />
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};
export default Projects;
