import SortableTable, { makeColumnObj } from "../modules/SortableTable";
import { apiFetch } from "../../helpers/apiFetch";
import {
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { useAuth, useCachedCollectionData } from "../../helpers/firebase";

import React, { useState } from "react";

const ReviewRow = ({ keyword, type, onButtonClick }) => {
  const changes = type === "accept" ? "acceptedChanges" : "rejectedChanges";
  const {
    [changes]: { names = [], toCategory },
  } = keyword || {};
  const [name] = names;

  return (
    <TableRow>
      <TableCell
        style={{
          width: "350px",
        }}
      >
        {name}
      </TableCell>
      <TableCell
        style={{
          width: "250px",
        }}
      >
        {toCategory}
      </TableCell>
      <TableCell
        style={{
          width: "250px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onButtonClick(name, toCategory, type)}
        >
          Undo
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ButtonDialog = ({
  buttonDialogOpen,
  setButtonDialogOpen,
  keyword: key,
}) => {
  const [user] = useAuth();
  const { keyword = "", toCategory = "", type = "" } = key || {};

  const onUndoButtonClick = (keyword, type) =>
    apiFetch({
      user,
      method: "POST",
      path: "/keywords/undo",
      data: { keyword, type, toCategory },
    });

  return (
    <>
      <Dialog open={buttonDialogOpen}>
        <DialogTitle>
          {`"${keyword}" was ${
            type === "accept" ? "accepted" : "rejected"
          } as a(n)
          "${toCategory.toUpperCase()}", would you like to undo this change?`}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              onUndoButtonClick(keyword, type);
              setButtonDialogOpen(false);
            }}
          >
            Undo
          </Button>
          <Button onClick={() => setButtonDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ReviewChanges = () => {
  const allKeywords = useCachedCollectionData("monitorCategoryChanges");

  const getKeywords = (allKeywords, type) =>
    allKeywords
      .map((keyword) => {
        const { names } = keyword;
        if (!keyword[type]) {
          return null;
        }
        return keyword[type].map((change) => {
          const { toCategory } = change;
          return { [type]: { toCategory, names } };
        });
      })
      .filter((change) => change)
      .flat();

  const acceptedChanges = getKeywords(allKeywords, "acceptedChanges");
  const rejectedChanges = getKeywords(allKeywords, "rejectedChanges");

  const [buttonDialogOpen, setButtonDialogOpen] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const acceptedColumns = [
    makeColumnObj("Accepted Changes", "acceptedChanges"),
    makeColumnObj("New Category", "newCategory"),
    makeColumnObj("Undo", "undo"),
  ];
  const rejectedColumns = [
    makeColumnObj("Rejected Changes", "rejectedChanges"),
    makeColumnObj("New Category", "newCategory"),
    makeColumnObj("Undo", "undo"),
  ];

  const onButtonClick = (name, toCategory, type) => {
    setKeyword({ keyword: name, toCategory, type });
    setButtonDialogOpen(true);
  };

  return (
    <>
      <ButtonDialog
        buttonDialogOpen={buttonDialogOpen}
        setButtonDialogOpen={setButtonDialogOpen}
        keyword={keyword}
      />
      <SortableTable
        size="small"
        rows={acceptedChanges}
        style={{ fontSize: "16pt" }}
        columns={acceptedColumns}
        rowComponent={(row, idx) => (
          <ReviewRow
            key={`project-row-${idx}`}
            keyword={row}
            type={"accept"}
            onButtonClick={onButtonClick}
            setButtonDialogOpen={setButtonDialogOpen}
          />
        )}
      />
      <SortableTable
        size="small"
        rows={rejectedChanges}
        style={{ fontSize: "16pt", paddingTop: "100px" }}
        columns={rejectedColumns}
        rowComponent={(row, idx) => (
          <ReviewRow
            key={`project-row-${idx}`}
            keyword={row}
            type={"reject"}
            onButtonClick={onButtonClick}
            setButtonDialogOpen={setButtonDialogOpen}
          />
        )}
      />
    </>
  );
};
export default ReviewChanges;
