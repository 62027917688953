import React from "react";

import TextField from "@material-ui/core/TextField";

const TextFieldComp = ({
  label,
  helperText,
  value,
  setValue,
  error,
  large,
  disabled,
  multiline = false,
  rows,
  id,
  InputProps,
  InputLabelProps,
  variant = "outlined",
}) => (
  <TextField
    autoComplete="chrome-off"
    label={label}
    helperText={helperText}
    value={value || ""}
    onChange={(e) => setValue(e.target.value)}
    fullWidth
    variant={variant}
    error={error}
    size={large ? "medium" : "small"}
    disabled={disabled}
    multiline={multiline}
    rows={rows}
    id={id}
    InputProps={InputProps}
    InputLabelProps={InputLabelProps}
  />
);
export default TextFieldComp;
