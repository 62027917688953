import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import {
  Grid,
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  IconButton,
  Radio,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";

import {
  formatDate,
  formatDecimal,
  formatDollars,
  ucFirstLetters,
} from "@aclymatedev/formatters";
import { tonsToLbs } from "@aclymatedev/converters";
import {
  useAdminProjects,
  createRankedOffsetList,
  PurchaseInputOptions,
} from "@aclymatedev/offsets-purchase-display";

import LoadingButton from "./atoms/LoadingButton";

import { useCompanyDetails } from "../helpers/companyDetails";
import { aclymateApiFetch } from "../helpers/apiFetch";

dayjs.extend(duration);

const ProjectDetailsBox = ({
  setProjectDetailsOpen,
  name,
  mechanism,
  registry,
  type,
  unSdgs = [],
}) => {
  const infoArray = [
    `Mechanism: ${mechanism}`,
    `Registry: ${registry}`,
    `Type: ${type}`,
    `UN SDGs: ${unSdgs.length ? unSdgs.map((sdg) => `${sdg}`) : "None"}`,
  ];
  return (
    <Dialog open onClose={() => setProjectDetailsOpen(null)}>
      <DialogTitle>{`More details on the ${name} project`}</DialogTitle>
      <DialogContent>
        {infoArray.map((info) => (
          <Typography variant="body1">{info}</Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const OffsetInfoBox = ({ selectedProject, setSelectedProject, project }) => {
  const [projectDetailsOpen, setProjectDetailsOpen] = useState();

  const { slug, name, totalThousandLbsCost, availableCarbonLbs, location } =
    project;
  const infoArray = [
    name,
    location,
    `Cost per thousand lbs: ${formatDollars(totalThousandLbsCost)}`,
    `Available lbs remaining: ${formatDecimal(availableCarbonLbs)} lbs`,
  ];

  return (
    <>
      {projectDetailsOpen && (
        <ProjectDetailsBox
          setProjectDetailsOpen={setProjectDetailsOpen}
          {...project}
        />
      )}
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item>
          <Radio
            checked={selectedProject === slug}
            onChange={(e) => setSelectedProject(e.target.value)}
            value={slug}
          />
        </Grid>
        <Grid item>
          {infoArray.map((info) => (
            <Typography variant="body1">{info}</Typography>
          ))}
        </Grid>
        <Grid item>
          <IconButton onClick={() => setProjectDetailsOpen(true)}>
            <Tooltip title="More project details">
              <InfoIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export const OffsetPreferencesText = ({
  offsetPreferences,
  showSecondaryText,
}) => {
  const generateFactorText = (factor) => {
    if (factor === "verificationStatus") {
      return "Verification Status";
    }

    if (factor === "unSdgs") {
      return "UN SDGs";
    }

    return ucFirstLetters(factor);
  };

  const generateSecondaryText = (preference) => {
    const {
      factor,
      rank,
      mechanisms,
      idealPrice,
      maxPrice,
      locations,
      subcategories,
      unSdgs,
    } = preference;
    const factorList = locations || subcategories || unSdgs;

    if (!rank) {
      return "We will not use this preference to find offset projects for you.";
    }

    if (factor === "mechanism") {
      return mechanisms?.length > 1
        ? "All mechanisms"
        : ucFirstLetters(mechanisms[0]);
    }

    if (factor === "pricing") {
      return `Ideal price: ${formatDollars(
        idealPrice
      )}; Max price: ${formatDollars(maxPrice)}`;
    }

    if (factor === "verificationStatus") {
      return "You prefer that offset projects should be verified";
    }

    return factorList.map(
      ({ factor }, idx) =>
        `${ucFirstLetters(factor)}${idx !== factorList?.length - 1 ? `, ` : ``}`
    );
  };

  return (
    <>
      {offsetPreferences && offsetPreferences?.length ? (
        offsetPreferences.map((preference) => (
          <>
            <Typography variant="body1">
              {generateFactorText(preference?.factor)}
            </Typography>
            {showSecondaryText && (
              <Typography variant="body2" color="textSecondary">
                {generateSecondaryText(preference)}
              </Typography>
            )}
          </>
        ))
      ) : (
        <Typography variant="body1">No offset preferences recorded</Typography>
      )}
    </>
  );
};

export const LastOffsetPurchasedText = ({ mostRecentOffset }) => {
  const {
    tonsCo2e = 0,
    totalPrice = 0,
    name: mostRecentOffsetName,
    date,
    totalOffsetCost = 0,
    aclymateTransactionCost = 0,
  } = mostRecentOffset || {};

  return (
    <>
      {mostRecentOffset ? (
        <>
          <Typography variant="body1" align="center">
            {`Name: ${mostRecentOffsetName}`}
          </Typography>
          <Typography variant="body1" align="center">
            {`Date: ${formatDate(date)}`}
          </Typography>
          <Typography variant="body1" align="center">
            {`Total volume: ${formatDecimal(tonsToLbs(tonsCo2e))} lbs`}
          </Typography>
          <Typography variant="body1" align="center">
            {`Cost of goods: ${formatDollars(totalOffsetCost)}`}
          </Typography>
          <Typography variant="body1" align="center">
            {`Net revenue: ${formatDollars(aclymateTransactionCost)}`}
          </Typography>
          <Typography variant="body1" align="center">
            {`Total revenue: ${formatDollars(totalPrice)}`}
          </Typography>
        </>
      ) : (
        <Typography variant="body1">No offsets purchased</Typography>
      )}
    </>
  );
};

const PurchaseOffsetsForCompany = ({
  setPurchaseOffsetsForCompany,
  name,
  offsetPreferences,
  id: companyId,
  users,
}) => {
  const [companyDetails, companyDetailsLoading] = useCompanyDetails(companyId);
  const [adminProjects, adminProjectsLoading] = useAdminProjects({
    isProduction: process.env.REACT_APP_ENVIRONMENT === "production",
    givenCompanyId: companyId,
  });

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [numProjectsToShow, setNumProjectsToShow] = useState(4);
  const [purchaseOffsetLoading, setPurchaseOffsetLoading] = useState();
  const [purchaseStatusMessage, setPurchaseStatusMessage] = useState();
  const [purchaseDollars, setPurchaseDollars] = useState(0);
  const [lbsToPurchase, setLbsToPurchase] = useState(0);
  const [climateLeaderDate, setClimateLeaderDate] = useState(null);

  useEffect(() => {
    if (adminProjects.length) {
      setProjects(
        createRankedOffsetList({ offsetPreferences, projects: adminProjects })
      );
    }
  }, [offsetPreferences, adminProjects]);

  const {
    currentCarbonBalanceTons,
    lastMonthsEmissionsTons,
    averageMonthlyRecurringEmissionsTons,
    certificationDate,
    offsets = [],
  } = companyDetails;
  const emissionsDetails = [
    {
      title: "Current carbon balance",
      value: currentCarbonBalanceTons,
    },
    {
      title: "Last months emissions",
      value: lastMonthsEmissionsTons,
    },
    {
      title: "Average monthly recurring emissions",
      value: averageMonthlyRecurringEmissionsTons,
    },
  ];

  const mostRecentOffset = offsets.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  )[0];

  const sortedProjects = !offsetPreferences
    ? projects.sort(
        (a, b) => b.aclymateCostPerThousandLbs - a.aclymateCostPerThousandLbs
      )
    : projects.sort((a, b) => b.totalScore - a.totalScore);
  const projectsToShow = sortedProjects.slice(0, numProjectsToShow);

  const selectedProjectData =
    projects.find(({ slug }) => slug === selectedProject) || {};
  const {
    totalThousandLbsCost = 0,
    providerCostPerThousandLbs = 0,
    aclymateCostPerThousandLbs = 0,
  } = selectedProjectData;
  const calculateCost = (perThousandLbsCost) =>
    (lbsToPurchase / 1000) * perThousandLbsCost;
  const totalRevenue = calculateCost(totalThousandLbsCost);
  const costOfGoods = calculateCost(providerCostPerThousandLbs);
  const netRevenue = calculateCost(aclymateCostPerThousandLbs);

  const defaultCertificationDate = certificationDate
    ? dayjs(certificationDate)
    : dayjs();
  const currentCarbonBalanceLbs = Math.abs(tonsToLbs(currentCarbonBalanceTons));
  const monthlyAverageCarbonLbs = tonsToLbs(
    averageMonthlyRecurringEmissionsTons
  );

  const convertLbsCarbonToCertificationDate = (lbsToPurchase) => {
    const additionalMonths =
      (Number(lbsToPurchase) - currentCarbonBalanceLbs) /
      monthlyAverageCarbonLbs;

    const additionalMonthsDurationsDays = dayjs
      .duration({
        months: additionalMonths,
      })
      .asDays();

    return defaultCertificationDate
      .add(additionalMonthsDurationsDays, "day")
      .toDate();
  };

  const convertCertificationDateToLbsCarbon = (certificationDate) => {
    const monthsUntilCertificationDate = dayjs(certificationDate).diff(
      defaultCertificationDate,
      "months",
      true
    );

    return (
      monthsUntilCertificationDate * monthlyAverageCarbonLbs +
      currentCarbonBalanceLbs
    );
  };

  const purchaseOffsets = async () => {
    setPurchaseOffsetLoading(true);
    const {
      slug: projectSlug,
      state,
      name: projectName,
      type: offsetType,
      typeSlug: offsetTypeSlug,
      coordinates: latLng,
      description,
      images,
      id,
      registry,
      registryUrl,
      country,
      offsetProvider,
      distanceMi,
      aclymateCostPerThousandLbs,
      providerCostPerThousandLbs,
    } = selectedProjectData;

    const { message } = await aclymateApiFetch({
      companyId,
      path: "/stripe/admin-offset-subscription-purchase",
      method: "POST",
      data: {
        companyId,
        projectSlug,
        email: users[0]?.email,
        projectData: {
          state,
          projectName,
          offsetType,
          offsetTypeSlug,
          latLng,
          description,
          images,
          id,
          registry,
          registryUrl,
        },
        country,
        offsetProvider,
        lbsToPurchase: Number(lbsToPurchase),
        distanceMi,
        aclymateCostPerThousandLbs,
        providerCostPerThousandLbs,
      },
      callback: (res) => res,
    });

    setPurchaseOffsetLoading(false);
    return setPurchaseStatusMessage(message);
  };

  return (
    <Dialog
      open
      onClose={() => setPurchaseOffsetsForCompany(null)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography
          align="center"
          variant="h5"
        >{`Purchase offsets for ${name}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} direction="column" alignItems="center">
          {companyDetailsLoading || adminProjectsLoading ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item container justifyContent="center" spacing={2}>
                {emissionsDetails.map(({ title, value }) => (
                  <Grid item xs={4}>
                    <Typography variant="body1" align="center">
                      {title}
                    </Typography>
                    <Typography variant="h6" align="center">
                      {`${formatDecimal(tonsToLbs(value))} lbs`}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid item container justifyContent="center" spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" align="center">
                    Offset preferences
                  </Typography>
                  <OffsetPreferencesText
                    offsetPreferences={offsetPreferences}
                    showSecondaryText
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" align="center">
                    Last offset purchased
                  </Typography>
                  <LastOffsetPurchasedText
                    mostRecentOffset={mostRecentOffset}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6" align="center">
                    Offset projects
                  </Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {projectsToShow.map((project) => (
                    <Grid item xs={6}>
                      <OffsetInfoBox
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                        project={project}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        setNumProjectsToShow((numProjects) => numProjects - 4)
                      }
                      disabled={numProjectsToShow === 4}
                    >
                      Show less projects
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setNumProjectsToShow((numProjects) => numProjects + 4)
                      }
                      disabled={numProjectsToShow > sortedProjects.length}
                    >
                      Show more projects
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <PurchaseInputOptions
                  purchaseDollars={purchaseDollars}
                  setPurchaseDollars={setPurchaseDollars}
                  lbsToPurchase={lbsToPurchase}
                  setLbsToPurchase={setLbsToPurchase}
                  climateLeaderDate={climateLeaderDate}
                  setClimateLeaderDate={setClimateLeaderDate}
                  totalCostPerThousandLbs={totalThousandLbsCost}
                  currentCarbonBalanceTons={currentCarbonBalanceTons}
                  convertLbsCarbonToCertificationDate={
                    convertLbsCarbonToCertificationDate
                  }
                  convertCertificationDateToLbsCarbon={
                    convertCertificationDateToLbsCarbon
                  }
                  certificationDate={certificationDate}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {`Cost of goods: ${formatDollars(costOfGoods)}`}
                </Typography>
                <Typography variant="body1">
                  {`Net revenue: ${formatDollars(netRevenue)}`}
                </Typography>
                <Typography variant="body1">
                  {`Total revenue: ${formatDollars(totalRevenue)}`}
                </Typography>
              </Grid>
              {purchaseStatusMessage && (
                <Grid item>
                  <Typography variant="body2" color="secondary">
                    {purchaseStatusMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  label="Purchase offsets"
                  disabled={
                    !selectedProject ||
                    !lbsToPurchase ||
                    (lbsToPurchase && isNaN(lbsToPurchase)) ||
                    purchaseStatusMessage
                  }
                  isLoading={purchaseOffsetLoading}
                  onClick={() => purchaseOffsets()}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default PurchaseOffsetsForCompany;
