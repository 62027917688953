import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import firebase from "firebase";

import { Grid, Typography, Button, Container } from "@material-ui/core";

import TextField from "./atoms/TextField";
import PasswordInput from "./atoms/PasswordInput";
import Layout from "./Layout";

import { useAuth } from "../helpers/firebase";

const Login = () => {
  const [loggedInUser, loggedInUserLoading] = useAuth();

  const [redirect, setRedirect] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (!loggedInUserLoading && loggedInUser?.uid) {
      setRedirect("/home");
    }
  }, [loggedInUser, loggedInUserLoading]);

  return (
    <>
      {redirect ? (
        <Redirect to={redirect} />
      ) : (
        <Layout>
          <Container maxWidth="sm">
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  label="Email Address"
                  value={userEmail}
                  setValue={setUserEmail}
                />
              </Grid>
              <Grid item>
                <PasswordInput password={password} setPassword={setPassword} />
              </Grid>
              {!!errorMsg && (
                <Grid item>
                  <Typography color="error">{errorMsg}</Typography>
                </Grid>
              )}
              <Grid container item justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    // disabled={!emailRegExpTest(userEmail) || !password.length >= 12}
                    onClick={() =>
                      firebase
                        .auth()
                        .signInWithEmailAndPassword(userEmail, password)
                        .then(() => setRedirect("/home"))
                        .catch(({ message }) => setErrorMsg(message))
                    }
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      )}
    </>
  );
};
export default Login;
