import { useState, useEffect } from "react";

import { apiFetch } from "./apiFetch";
import { useAuth } from "./firebase";

export const useCompanyDetails = (companyId) => {
  const [user, userLoading] = useAuth();
  const [companyDetails, setCompanyDetails] = useState({});
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (companyId && user && !userLoading) {
      apiFetch({
        user,
        path: `/companies/fetch-company-data/${companyId}/${process.env.REACT_APP_ENVIRONMENT}`,
        method: "GET",
        callback: (res) => setCompanyDetails(res),
      }).then(() => setDataLoading(false));
    }
  }, [companyId, user, userLoading]);

  return [companyDetails, dataLoading];
};
