import React, { useState } from "react";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export const makeColumnObj = (name, column = null, sortable = false) => ({
  name,
  column,
  sortable,
});

const SortableTable = ({
  rows,
  rowComponent,
  columns,
  size,
  style = {},
  headerStyle = {},
  order,
  setOrder,
  orderBy,
  setOrderBy,
}) => {
  const { innerHeight } = window;
  const [editRowSelected, setEditRowSelected] = useState(false);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const EnhancedTableHead = () => {
    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };

    const TableHeadCell = ({
      column,
      name,
      sortable,
      orderBy,
      order,
      style = {},
    }) => (
      <TableCell
        sortDirection={orderBy === column ? order : false}
        id={`${column}_HeadCell`}
        style={style}
      >
        {sortable ? (
          <TableSortLabel
            active={orderBy === column}
            direction={orderBy === column ? order : "asc"}
            onClick={createSortHandler(column)}
          >
            {name}
          </TableSortLabel>
        ) : (
          name
        )}
      </TableCell>
    );

    return (
      <TableHead>
        <TableRow>
          {columns.map((column, idx) => (
            <TableHeadCell
              key={`table-head-cell-${idx}`}
              column={column.column}
              name={column.name}
              style={headerStyle}
              sortable={column.sortable}
              orderBy={orderBy}
              order={order}
            />
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <TableContainer
        style={{
          maxHeight: innerHeight - 250,
          ...style,
        }}
      >
        <Table stickyHeader size={size || "small"}>
          <EnhancedTableHead />
          <TableBody style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
            {rows.map((row, idx) => (
              <React.Fragment key={`sortable-table-row-${idx}`}>
                {rowComponent(row, idx, editRowSelected, setEditRowSelected)}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default SortableTable;
