import React from "react";

import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Container,
  IconButton,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { useAuth, signOut } from "../helpers/firebase";

const Layout = ({ title, children }) => {
  const [user] = useAuth();
  const { email } = user || {};

  return (
    <Grid container direction="column" spacing={2} style={{ height: "100vh" }}>
      <Grid item>
        <AppBar position="static">
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container>
                  <Grid item>
                    <IconButton href="/home" color="inherit">
                      <HomeIcon color="inherit" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">{title}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h4">Aclymate Admin Portal</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="h6">{email}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => signOut()} color="inherit">
                      <ExitToAppIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid
        container
        direction="column"
        item
        style={{ flexGrow: 1 }}
        justifyContent="center"
      >
        <Grid item>
          <Container maxWidth="lg">{children}</Container>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Layout;
