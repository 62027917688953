import React from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { useAuth } from "../helpers/firebase";
import Home from "./Home";
import Projects from "./Projects";
import Accounts from "./Accounts";
import Keywords from "./keywords/index";

const PlatformRouter = () => {
  const [user, userLoading] = useAuth();
  const { page } = useParams();

  if (!userLoading && !user) {
    return <Redirect to="/" />;
  }

  if (page === "accounts") {
    return <Accounts />;
  }
  if (page === "projects") {
    return <Projects />;
  }
  if (page === "home") {
    return <Home />;
  }
  if (page === "keywords") {
    return <Keywords />;
  }
  return <></>;
};
export default PlatformRouter;
