import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DialogBox = ({
  open,
  setOpen,
  title,
  contentText,
  contentBlock,
  actions,
  maxWidth,
  fullWidth = false,
  disableBackdropClick = false,
  confirmClose = false,
  confirmCloseMessage,
  style = {},
}) => {
  // const { activateSnackbar } = useContext(PlatformLayoutContext);

  // const onBackdropClick = () => {
  //   if (confirmClose) {
  //     return activateSnackbar({
  //       message:
  //         confirmCloseMessage || "Are you sure you want to exit this process.",
  //       alert: "warning",
  //       onClose: () => setOpen(false),
  //     });
  //   }

  //   return setOpen && setOpen(false);
  // };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth || false}
      onClose={() => setOpen && setOpen(false)}
      fullWidth={fullWidth}
      // disableBackdropClick={disableBackdropClick || confirmClose}
      // onBackdropClick={() => onBackdropClick()}
      scroll="body"
      style={{ ...style }}
      disableEnforceFocus
    >
      {typeof title === "string" ? <DialogTitle>{title}</DialogTitle> : title}
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>{contentText}</DialogContentText>
        {contentBlock}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
export default DialogBox;
