import KeywordsView from "./Keywords";
import ReviewView from "./ReviewChanges";

import ToggleButtons from "../atoms/ToggleButtons";
import { Grid, Button } from "@material-ui/core";
const { useEffect, useState } = require("react");

const KeywordsViewSwitcher = ({ view }) => {
  if (!view) {
    return <></>;
  }
  if (view === "new") {
    return <KeywordsView />;
  }
  if (view === "review") {
    return <ReviewView />;
  }
};

const Keywords = () => {
  const [keywordsView, setKeywordsView] = useState("new");

  useEffect(() => {
    if (!keywordsView) {
      setKeywordsView("new");
    }
  }, [keywordsView]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid
        item
        container
        spacing={2}
        justifyContent="space-between"
        xs={12}
        alignItems="center"
      >
        <Grid item sm={2}>
          <Button variant="contained" color="primary" href="/home">
            Home
          </Button>
        </Grid>
        <Grid item sm={2}>
          <ToggleButtons
            value={keywordsView}
            onChange={setKeywordsView}
            buttons={[
              { name: "New Keywords", value: "new" },
              { name: "Review Changes", value: "review" },
            ]}
          />
        </Grid>
      </Grid>
      <KeywordsViewSwitcher view={keywordsView} />
    </Grid>
  );
};
export default Keywords;
