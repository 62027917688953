import fetchAclymateApi from "@aclymatedev/fetch-aclymate-api";

const getBaseFetchHost = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:8500/admin-test-91820/us-central1/adminApi";
  }

  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return "https://us-central1-aclymate-admin.cloudfunctions.net/adminApi";
  }

  return "https://us-central1-admin-test-91820.cloudfunctions.net/adminApi";
};

export const apiFetch = async ({
  user: { uid },
  path,
  method,
  data,
  callback,
  signal,
}) => {
  const baseFetchHost = getBaseFetchHost();

  const body = method === "POST" ? { body: JSON.stringify(data) } : {};
  return await fetch(`${baseFetchHost}${path}`, {
    method,
    mode: "cors",
    headers: { "Content-Type": "application/json", "x-auth-token": uid },
    ...body,
    signal,
  })
    .then((response) => {
      if (callback) {
        return response.json();
      }
      return null;
    })
    .then((data) => {
      if (data?.error) {
        console.log("API error: " + data.message);
        return {};
      }
      return callback && callback(data);
    })
    .catch((e) => {
      console.log(e);
      return { error: true };
    });
};

export const aclymateApiFetch = async ({ companyId = "", ...otherProps }) => {
  const baseFetchHost =
    process.env.REACT_APP_ENVIRONMENT === "test"
      ? "https://us-central1-aclymate-test-74e62.cloudfunctions.net/app"
      : "https://us-central1-renew-west.cloudfunctions.net/app";

  return await fetchAclymateApi({
    baseFetchHost,
    companyId,
    ...otherProps,
  });
};
