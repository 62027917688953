export const formatDate = (date) => {
  if (!date) {
    return "";
  }
  const rowDate = Date.parse(date);
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "2-digit",
    month: "2-digit",
    day: "numeric",
  });
  const [{ value: month }, , { value: day }, , { value: year }] =
    dateTimeFormat.formatToParts(rowDate);
  return `${month}/${day}/${year}`;
};

export const formatDollars = (dollars) =>
  Number.isNaN(dollars * 1)
    ? ""
    : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(dollars);

export const formatDecimal = (decimal) =>
  new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(decimal);

export const ucFirstLetters = (string) =>
  string
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const letterSBoolean = (value) =>
  (Array.isArray(value) && (value.length > 1 || !value.length)) ||
  Number(value) > 1 ||
  !value
    ? "s"
    : "";
